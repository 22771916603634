import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
    Autocomplete,
    Box,
    Chip,
    Paper,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { useTheme } from "@mui/material/styles";
import { useUpdateEffect } from "src/utils/use-update-effect";
import { useEffect } from "react";
import { add, isArray } from "lodash";
import { getCountryCode, removeEmojis } from "src/utils/locations";
import ReactCountryFlag from "react-country-flag";

const filterOptions = createFilterOptions();

export const CollectionSearch = forwardRef((props, ref) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
    const { initialFilters, onFiltersChange, options = [], fixStaff = false, fixedOption = null, forceOne = null, label = "Search", multiple = true, freeSolo = false, disabled = false, addNew = false, fixedHeight = false } = props;
    const queryRef = useRef(null);
    const inputRef = useRef(null);
    const [filters, setFilters] = useState(initialFilters);
    const [fixedHeightMode, setFixedHeightMode] = useState(fixedHeight);

    useUpdateEffect(() => {
        onFiltersChange?.(filters);
    }, [filters]);

    useEffect(() => {
        if (fixedOption) {
            setFilters((prevState) => ({
                ...prevState,
                tags: [fixedOption],
            }));
        }
    }, [fixedOption]);

    useEffect(() => {
        if (forceOne) {
            setFilters((prevState) => ({
                tags: forceOne,
            }));
        }
    }, [forceOne]);

    const onFocus = () => {
        if (fixedHeight) {
            setFixedHeightMode(false);
        }
    };

    const onBlur = () => {
        if (fixedHeight) {
            setFixedHeightMode(true);
        }
    }

    // If fixedOption is set, remove it from the list of options
    const newOptions = !!fixedOption ? options.filter(option => option.id !== fixedOption.id) : options;
    const moreThanOneType = new Set(options.map(option => option.type)).size > 1;

    const CustomPaper = function (props) {
        return <Paper {...props} style={{ border: '1px solid #ccc', borderRadius: '4px' }} />;
    };

    const onChange = (event, value) => {
        let uniqueValues = value;
        // If value is an array, it means that it is multi-select
        console.log("VALUE ", value);
        if (isArray(value)) {
            console.log("VALUE IS ARRAY ", value);
            const newValues = value.map((val) => {
                const isAddNew = val.value.startsWith('Add "') && val.value.endsWith('"') && addNew;
                // Remove the 'Add "' and '"'
                val.value = isAddNew ? val.value.slice(5, -1) : val.value;

                return val;
            });

            // Ensure they are unqiue values
            uniqueValues = newValues.filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index);
            console.log("UNIQUE VALUES ", uniqueValues);
        }

        const newFilters = {
            ...filters,
            tags: uniqueValues,
        };

        setFilters(newFilters);
    };

    useImperativeHandle(ref, () => ({
        addTag: (tag, focus = true) => {
            setFilters((prevState) => ({
                ...prevState,
                tags: multiple ? [...prevState.tags, tag] : { ...tag },
            }));
            if (focus) {
                inputRef.current?.focus();
            }
        },
        removeTag: (tagId) => {
            setFilters((prevState) => ({
                ...prevState,
                tags: prevState.tags.filter((t) => t.id !== tagId),
            }));
        },
        clear: () => {
            setFilters({
                tags: [],
            });
        },
    }));

    const selectedContainsFlagEmoji = typeof filters.tags === "object" && (filters.tags.type === "Country" || filters.tags.type === "International" || filters.tags.type === "Canada") && !filters.tags.value?.includes("Anywhere");

    return (
        <Box component="form" sx={{ flex: 1 }}>
            <Autocomplete
                ref={queryRef}
                // id="collection-search"
                PaperComponent={CustomPaper}
                disabled={disabled}
                multiple={multiple}
                freeSolo={freeSolo}
                value={filters.tags}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                disableClearable={!!fixedOption || !multiple}
                options={newOptions.sort((a, b) => {
                    // First, sort by type
                    const typeComparison = a.type.localeCompare(b.type);
                    if (typeComparison !== 0) return typeComparison;

                    // If types are equal and 'total' field exists, sort by 'total'
                    if ('total' in a && 'total' in b) {
                        return b.total.localeCompare(a.total);
                    }

                    // If types are equal and 'total' field does not exist in either, return 0
                    return 0;
                })}
                groupBy={moreThanOneType ? (option) => option.type : undefined}
                getOptionLabel={(option) => selectedContainsFlagEmoji ? removeEmojis(option.value).trimStart() : option.value}
                renderInput={(params) => (
                    <TextField
                        inputRef={inputRef}
                        {...params}
                        label={label}
                        sx={{
                            "& .MuiInputBase-root": {
                                pt: "25px",
                                pb: "5px",
                                maxHeight: fixedHeightMode ? "66px" : "auto",
                                overflowY: "scroll",
                            }
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: selectedContainsFlagEmoji ? (
                                <ReactCountryFlag svg countryCode={getCountryCode(filters.tags.value)} />
                            ) : params.InputProps.startAdornment,
                        }}
                    />
                )}
                onFocus={onFocus}
                onBlur={onBlur}
                filterOptions={(options, params) => {
                    const filtered = filterOptions(options, params);

                    if (addNew) {
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option.value);
                        if (inputValue !== '' && !isExisting) {
                            filtered.push({
                                id: inputValue,
                                type: "Label",
                                value: `Add "${inputValue}"`,
                            });
                        }
                    }

                    return filtered;
                }}
                onChange={onChange}
                renderTags={(tagValue, getTagProps) => (filters.tags).map((option, index) => (
                    <Chip
                        label={`${option.meta ? "Shift Series: " : ""}${option.value}`}
                        {...getTagProps({ index })}
                        disabled={(option && fixedOption && option.id == fixedOption.id) || disabled || (fixStaff && (option.id === "Staff / Faculty Member" || option.id === "Instructor / Professor"))}
                    />
                ))}
                isOptionEqualToValue={(option, value) => {
                    // console.log("OPTION ", option, " VALUE ", value);
                    return option.id === value.id;
                }}
                renderOption={(props, option, { selected }) => (
                    <Stack {...props} key={`${option.value}_${option.meta ?? ''}`} sx={{
                        backgroundColor: selected ? theme.palette.action.selected : "transparent",
                        paddingTop: "10px !important",
                    }}>
                        <Stack spacing={1} direction="row" width="100%" justifyContent="space-between" display="flow-root" alignSelf="flex-start">
                            <Box
                                sx={{
                                    flexDirection: "row",
                                    alignSelf: "flex-start",
                                    textAlign: "left",
                                    float: "left",
                                    display: (option.type === "Country" || option.type === "International" || option.type === "Canada") ? "inline-flex" : "initial",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    columnGap: "6px",
                                }}
                            >
                                {(option.type === "Country" || option.type === "International" || option.type === "Canada") && (
                                    <ReactCountryFlag svg countryCode={getCountryCode(option.value)} />
                                )}
                                <Typography sx={{
                                    display: "contents",
                                    alignSelf: "flex-start",
                                    textAlign: "left",
                                    fontWeight: option.meta ? 600 : 'normal',
                                }}>
                                    {
                                        (option.type === "Country" || option.type === "International" || option.type === "Canada") && !option.value?.includes("Anywhere") ? (
                                            removeEmojis(option.value)
                                        ) : option.value
                                    }
                                </Typography>
                            </Box>
                            {option.total && (
                                <Typography sx={{
                                    alignSelf: "flex-end",
                                    textAlign: "right",
                                    fontWeight: 600,
                                    float: "right",
                                    color: theme.palette.grey[500],
                                }}>
                                    {option.total}
                                </Typography>
                            )}
                        </Stack>
                        {option.meta && (
                            <Typography {...props} sx={{
                                alignSelf: "flex-start",
                                textAlign: "left",
                                ml: "-12px",
                                p: 0,
                                color: theme.palette.grey[500],
                                fontSize: "0.8rem",
                                backgroundColor: "transparent !important",
                            }}>
                                {option.meta}
                            </Typography>
                        )}
                    </Stack>
                )}
            />
        </Box>
    );
});

CollectionSearch.propTypes = {
    initialFilters: PropTypes.object,
    onFiltersChange: PropTypes.func,
    options: PropTypes.array,
    fixedOption: PropTypes.object,
};
